// ** Mui & React
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Popover,
  Stack,
  Typography,
} from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'

// ** Utils
import { getAvatarUrl } from '@/utils'
import { ProfileFragment } from '@lens-protocol/client'
import { useLens } from '@/context/lens'

interface ProfileHoverProps {
  user?: ProfileFragment
  formattedUser?: {
    profileId: string
    handle: string
    name: string
    bio: string
    profilePic: string
  }
  children: ReactNode
}

export default function ProfileHover({
  user,
  formattedUser,
  children,
}: ProfileHoverProps) {
  const { isFollowedByMe, follow, unfollow } = useLens()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isFollow, setIsFollow] = useState(false)
  const [awaitFollowing, setAwaitFollowing] = useState(false)

  const profileId = user?.id || formattedUser?.profileId

  /*************************************************
   *                  Functions                    *
   *************************************************/

  useEffect(() => {
    if (isPopoverOpen && (user?.id || formattedUser?.profileId)) {
      setAwaitFollowing(true)
      checkFollowStatus()
    }
  }, [isPopoverOpen])

  const checkFollowStatus = async () => {
    try {
      if (!profileId) return
      const res = await isFollowedByMe(profileId)

      if (res?.[0]?.status?.value) {
        setIsFollow(true)
      } else {
        setIsFollow(false)
      }
      setAwaitFollowing(false)
    } catch (error) {
      console.error('Error checking follow status:', error)
    }
  }

  const handleFollow = async () => {
    setAwaitFollowing(true)
    if (!profileId) return
    if (isFollow) {
      await unfollow(profileId)
      checkFollowStatus()
    } else {
      await follow(profileId)
      checkFollowStatus()
    }
    setAwaitFollowing(false)
  }

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setIsPopoverOpen(true)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setIsPopoverOpen(false)
  }

  /*************************************************
   *                    Render                     *
   *************************************************/

  const FollowButton = () => {
    if (awaitFollowing) {
      return (
        <Button variant="contained" disabled size="small" sx={{ mt: 1 }}>
          <CircularProgress />
        </Button>
      )
    }
    if (!isFollow) {
      return (
        <Button
          variant="contained"
          size="small"
          sx={{ mt: 1 }}
          onClick={handleFollow}
        >
          Follow
        </Button>
      )
    }
    if (isFollow) {
      return (
        <Button
          variant="contained"
          size="small"
          color="error"
          sx={{ mt: 1 }}
          onClick={handleFollow}
        >
          Unfollow
        </Button>
      )
    } else {
      return (
        <Button variant="contained" disabled size="small" sx={{ mt: 1 }}>
          <CircularProgress />
        </Button>
      )
    }
  }

  return (
    <Box
      aria-owns={isPopoverOpen ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {children}

      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: 'none' }}
        open={isPopoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 400,
            pointerEvents: 'auto',
          },
        }}
        onMouseEnter={() => setIsPopoverOpen(true)}
        onMouseLeave={handlePopoverClose}
        disableRestoreFocus
        disableScrollLock
      >
        <Stack spacing={2} padding="0.2em" display="flex" width="100%">
          <Stack justifyContent="left">
            <Avatar
              src={getAvatarUrl(user) ?? formattedUser?.profilePic}
              sx={{ width: 60, height: 60, mb: 1 }}
              alt={user?.metadata?.displayName ?? formattedUser?.name ?? ''}
            />
            <Typography variant="h6">
              {user?.metadata?.displayName ?? formattedUser?.name ?? ''}
            </Typography>
          </Stack>
          <Typography variant="body2" color="textSecondary" align="left">
            {user?.metadata?.bio ?? formattedUser?.bio ?? ''}
          </Typography>
          <FollowButton />
        </Stack>
      </Popover>
    </Box>
  )
}
