import { useTheme } from '@mui/material/styles'
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Box,
  useMediaQuery,
  Theme,
} from '@mui/material'
import { useSelector } from '../../../src/store/Store'
import { AppState } from '../../../src/store/Store'

type Props = {
  title?: string
  subtitle?: string
  action?: JSX.Element | any
  footer?: JSX.Element
  cardheading?: string | JSX.Element
  headtitle?: string | JSX.Element
  headsubtitle?: string | JSX.Element
  children?: JSX.Element
  middlecontent?: string | JSX.Element
  contentPadding?: string
}

const DashboardCard = ({
  title,
  subtitle,
  children,
  action,
  footer,
  cardheading,
  headtitle,
  headsubtitle,
  middlecontent,
  contentPadding,
}: Props) => {
  const customizer = useSelector((state: AppState) => state.customizer)

  const theme = useTheme()
  const borderColor = theme.palette.divider
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <Card
      sx={{
        padding: 0,
        border: !customizer.isCardShadow ? `1px solid ${borderColor}` : 'none',
      }}
      elevation={customizer.isCardShadow ? 9 : 0}
      variant={!customizer.isCardShadow ? 'outlined' : undefined}
    >
      {cardheading ? (
        <CardContent>
          <Typography variant="h5">{headtitle}</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {headsubtitle}
          </Typography>
        </CardContent>
      ) : (
        <CardContent sx={{ p: contentPadding ? contentPadding : '30px' }}>
          {title ? (
            <Stack
              direction={smDown ? 'column' : 'row'}
              spacing={2}
              justifyContent="space-between"
              alignItems={'center'}
              mb={3}
            >
              <Box>
                {title ? <Typography variant="h5">{title}</Typography> : ''}

                {subtitle ? (
                  <Typography variant="subtitle2" color="textSecondary">
                    {subtitle}
                  </Typography>
                ) : (
                  ''
                )}
              </Box>
              {action}
            </Stack>
          ) : null}

          {children}
        </CardContent>
      )}

      {middlecontent}
      {footer}
    </Card>
  )
}

export default DashboardCard
